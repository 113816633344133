<template>
    <BT-Blade-Item
        bladeName="stock-import"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        :canSave="false"
        :loadingMsg="loadingMsg"
        navigation="stock-imports"
        :onPullSuccessAsync="pullImport"
        :refreshToggle="refreshToggle"
        :saveToggle="saveToggle"
        title="Stock Import">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" sm="6" class="d-flex justify-center align-center my-4">
                    <BT-Field-Company
                        :id="item.sellerID"
                        label="SENDER"
                        :title="item.seller.companyName" />
                    <!-- <BT-Entity
                        :itemValue="item.sellerID"
                        navigation="public-companies"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            
                        </template>
                    </BT-Entity> -->
                    <!-- <v-icon class="mx-1">mdi-arrow-right-bold</v-icon>
                    <BT-Entity
                        :itemValue="item.buyerID"
                        navigation="public-companies"
                        single
                        useLocalCache>
                        <template v-slot="{ item }">
                            <BT-Field-Company
                                :id="item.id"
                                :title="item.companyName" />
                        </template>
                    </BT-Entity> -->
                </v-col>

                <v-col cols="12" sm="6" class="d-flex justify-center align-center">
                    <span class="warning--text">*{{ item.isTransfer ? 'TRANSFER' : 'CONSIGNMENT' }}*</span>
                </v-col>
                
                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-model="item.departureLocation"
                        label="FROM" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-model="item.destinationLocation"
                        label="TO" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        v-model="item.consignmentNumber"
                        label="Consignment #" />
                </v-col>

                <v-col cols="6" sm="12">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On" />
                </v-col>

                <v-col v-if="!item.seller.isManagedByAnotherAccount" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>
                        Picked
                        <v-icon v-if="item.pickedOn == null" class="error--text" small right>mdi-close</v-icon>
                    </v-subheader>

                    <v-card v-if="item.pickedOn != null" class="success mx-1">
                        <v-card-text>
                            <v-icon small left>mdi-check</v-icon>
                            Picked on {{ item.pickedOn | toShortDateAndTime }}
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="!item.seller.isManagedByAnotherAccount" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>
                        Packed
                        <v-icon v-if="item.packedOn == null" class="error--text" small right>mdi-close</v-icon>
                    </v-subheader>

                    <v-card v-if="item.packedOn != null" class="success mx-1">
                        <v-card-text>
                            <v-icon small left>mdi-check</v-icon>
                            Packed on {{ item.packedOn | toShortDateAndTime }}
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col v-if="!item.seller.isManagedByAnotherAccount" cols="12" sm="4">
                    <v-divider />
                    <v-subheader>
                        Delivered
                        <v-icon v-if="item.performedOn == null" class="error--text" small right>mdi-close</v-icon>
                    </v-subheader>

                    <v-card v-if="item.isSellerResolved && item.pickedOn != null" class="success mx-1">
                        <v-card-text>
                            <v-icon small left>mdi-check</v-icon>
                            Delivered on {{ item.performedOn | toShortDateAndTime }}
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <v-divider class="my-1" />

                    <span v-if="item.isBuyerResolved">Import Complete</span>
                    <BT-Btn-Row v-else>
                        <template v-slot:right>
                            <BT-Btn
                                v-if="item.isTransfer && !isImporting"
                                @click="acceptEntryItems(item)"
                                :disabled="!item.isSellerResolved"
                                icon="mdi-transfer-down"
                                label="Import Transfer"
                                title="Can only import transfer after it's been delivered" />

                            <BT-Btn
                                v-if="!item.isTransfer && isImporting"
                                @click="acceptCustomItems(item, false)"
                                icon="mdi-transfer-down"
                                label="Import" />

                            <BT-Btn
                                v-if="!item.isTransfer && isImporting"
                                @click="acceptCustomItems(item, true)"
                                icon="mdi-transfer-down"
                                label="Import & Complete" />

                            <BT-Btn
                                v-if="!item.isTransfer && !isImporting && item.importedItemsCSV != null"
                                @click="isImporting = true"
                                icon="mdi-transfer-down"
                                label="Import More" />

                            <BT-Menu v-if="!item.isTransfer && !isImporting && item.importedItemsCSV == null" hideHeader left>
                                <template v-slot:activator="{ openDialog }">
                                    <BT-Btn
                                        @click="openDialog"
                                        icon="mdi-transfer-down"
                                        label="Import Order" />
                                </template>
                                <v-list-item v-if="item.importedItemsCSV == null" @click="acceptOrderItems(item)">
                                    <v-list-item-content>
                                        <v-list-item-title>Everything</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="isImporting = true">
                                    <v-list-item-content>
                                        <v-list-item-title>Partially</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </BT-Menu>
                        </template>
                    </BT-Btn-Row>
                </v-col>

                <v-col cols="12">
                    <v-subheader>Ordered Items</v-subheader>
                    <BT-List v-if="item.isTransfer" :items="item.consignmentOrderItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item dense>
                                <BT-List-Item-Avatar
                                    v-model="item.productID"
                                    avatarType="product" />
                                <v-list-item-content>
                                    <v-list-item-title v-if="item.product != null">{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        QTY: <strong>{{ item.quantityAssigned | toDisplayNumber }} / {{  item.quantity | toDisplayNumber }}</strong>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-List>
                    <BT-List v-if="!item.isTransfer" :items="item.consignmentOrderItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item dense>
                                <BT-List-Item-Avatar
                                    v-model="item.productID"
                                    avatarType="product" />
                                <v-list-item-content>
                                    <v-list-item-title v-if="item.product != null">{{ item.product.abbreviation }} - {{ item.product.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span title="Quantity Imported">{{ item.quantityImported | toDisplayNumber }}</span>
                                        /
                                        <span title="Quantity Ordered">{{ item.quantity | toDisplayNumber }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="isImporting">
                                    <BT-Number-Edit
                                        v-model.number="item.newQuantity"
                                        singleLine />
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </BT-List>

                </v-col>

                <v-col cols="12">

                </v-col>
            </v-row>
            
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Item>
</template>

<script>
import { toCamelCase } from '~helpers';

export default {
    name: 'Stock-Import-Blade',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue')
    },
    data: function() {
        return {
            isImporting: false,
            loadingMsg: null,
            msg: null,
            panelV: [0,1],
            refreshToggle: false,
            saveToggle: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async acceptOrderItems(consign) {
            try {
                this.loadingMsg = 'Importing Ordered Items';

                var res = await this.$BlitzIt.store.patch('stock-imports', {
                    id: consign.id,
                    rowVersion: consign.rowVersion,
                    acceptOrderItems: true
                });

                consign.rowVersion = res.rowVersion;
                consign.isBuyerResolved = true;
                this.$BlitzIt.store.deleteLocal('stock-imports', consign.id);
                this.$router.push({ name: 'stock-imports', params: { refresh: true } });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async acceptEntryItems(consign) {
            try {
                this.loadingMsg = 'Importing Stock';

                var res = await this.$BlitzIt.store.patch('stock-imports', {
                    id: consign.id,
                    rowVersion: consign.rowVersion,
                    acceptEntryItems: true
                });

                consign.rowVersion = res.rowVersion;
                consign.isBuyerResolved = true;
                this.$BlitzIt.store.deleteLocal('stock-imports', consign.id);
                this.$router.push({ name: 'stock-imports', params: { refresh: true } });
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async acceptCustomItems(consign, isComplete) {
            try {
                this.loadingMsg = 'Importing';

                var data = {
                    id: consign.id,
                    rowVersion: consign.rowVersion,
                    isComplete: isComplete,
                    importedItems: consign.consignmentOrderItems.map(x => {
                        return {
                            productID: x.productID,
                            batchID: null,
                            quantity: x.newQuantity
                        };
                    })
                };

                var res = await this.$BlitzIt.store.patch('stock-imports', data);

                consign.rowVersion = res.rowVersion;
                consign.isBuyerResolved = res.isBuyerResolved;

                if (!isComplete) {
                    this.refreshToggle = !this.refreshToggle;
                }
                else {
                    this.$BlitzIt.store.deleteLocal('stock-imports', consign.id);
                    this.$router.push({ name: 'stock-imports', params: { refresh: true } });
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        groupImportItems(importment) {
            var rList = [];

            importment.consignmentOrderItems.forEach(oItem => {
                rList.push({
                    ...oItem,
                    quantityAssigned: 0,
                    quantityImported: 0,
                    newQuantity: 0,
                    entryItems: [],
                    importedItems: []
                });
            });

            importment.consignmentEntryItems.forEach(eItem => {
                var existing = rList.find(z => z.productID == eItem.productID);
                if (existing == null) {
                    existing = {
                        productID: eItem.productID,
                        quantity: 0,
                        quantityAssigned: 0,
                        quantityImported: 0,
                        newQuantity: 0,
                        entryItems: [],
                        importedItems: []
                    };

                    rList.push(existing);
                }

                existing.quantityAssigned += eItem.quantity;
                existing.entryItems.push(eItem);
            })

            if (importment.importedItemsCSV != null) {
                var importedItems = JSON.parse(importment.importedItemsCSV, toCamelCase);

                importedItems.forEach(importedItem => {
                    var iExist = rList.find(z => z.productID == importedItem.productID);
                    if (iExist == null) {
                        iExist = {
                            productID: importedItem.productID,
                            quantity: 0,
                            quantityAssigned: 0,
                            quantityImported: 0,
                            newQuantity: 0,
                            entryItems: [],
                            importedItems: []
                        }

                        rList.push(iExist);
                    }

                    iExist.quantityImported += importedItem.quantity;
                    iExist.importedItems.push(importedItem);
                })
            }

            rList.forEach(rItem => {
                rItem.entryItems = rItem.entryItems.orderBy(z => z.batch == null ? 0 : z.batch.dom);
            })

            rList = rList.orderBy(x => x.product == null ? 0 : x.product.sortNumber);

            importment.consignmentOrderItems = rList;
        },
        async pullImport(importment) {
            this.groupImportItems(importment);
            return importment;
        },
    }
}
</script>